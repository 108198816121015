import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, catchError, map, mergeMap, of, switchMap, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { BcUser, CompanyBasic } from '../models/auth/BcUser';
import { UserPartner } from '../models/api/UserPartner';
import { UserContactPartner } from '../models/auth/UserContactPartner';
import { CompanyCountryViewModel } from '../models/api/CompanyCountry';
import { Router } from '@angular/router';
import { UserPartnersList } from '../models/api/UserPartnersList';
import { History } from '../models/api/History';
import { NgxPermissionsService } from 'ngx-permissions';
import { Role } from '@app/@shared/constants/role.constant';
import { LoginResponse, OidcSecurityService } from 'angular-auth-oidc-client';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private permissionStorageName: string = 'userPermissions';
  private userStorageName: string = 'currentUser';
  private partnerStorageName: string = 'currentPartner';
  public currentUserSubject: BehaviorSubject<BcUser>;
  public isLoggedIn: BehaviorSubject<Boolean>;

  public selectedPartner: BehaviorSubject<UserPartner>;
  public partners: BehaviorSubject<UserPartner[]>;
  public history: BehaviorSubject<History[]>;
  public partnersUpdated: Boolean = false;

  public isBcSuper: boolean = false;
  // public LicenseViewingMode: boolean = true;
  constructor(
    private http: HttpClient,
    private router: Router,
    private permissionsService: NgxPermissionsService,
    private oidcSecurityService: OidcSecurityService
  ) {
    this.currentUserSubject = new BehaviorSubject<BcUser>(null);
    this.isLoggedIn = new BehaviorSubject<Boolean>(false);
    this.selectedPartner = new BehaviorSubject<UserPartner>(null);
    this.partners = new BehaviorSubject<UserPartner[]>(null);
    this.initialSetUpUser();
  }

  public get currentUserValue(): BcUser {
    return this.currentUserSubject.value;
  }

  public get currentSelectedPartner(): UserPartner {
    return this.selectedPartner.value;
  }

  requestAccess(email: string, fullName: string, company: string, country: string) {
    return this.http.post(
      `${environment.apiUrl}/Authentication/RequestAccess`,
      { email, fullName, company, country },
      { responseType: 'text' }
    );
  }

  getCompaniesForEmail(email: string): Observable<any> {
    return this.http.get<Array<CompanyCountryViewModel>>(
      `${environment.apiUrl}/Authentication/email-company-list/${email}`
    );
  }

  logout() {
    this.oidcSecurityService.logoff().subscribe(() => {
      this.logoutLocally();
    });
  }

  logoutLocally() {
    // remove user from local storage to log user out
    sessionStorage.clear();
    localStorage.removeItem(this.userStorageName);
    localStorage.removeItem(this.partnerStorageName);
    localStorage.removeItem(this.permissionStorageName);
    this.isLoggedIn.next(false);
    this.currentUserSubject.next(null);
    this.selectedPartner.next(null);
    //window.location.href = environment.authenticationServiceUrl + '/Account/SignOff?returnUrl=' + environment.thisUrl;
    window.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=${environment.thisUrl}`;
  }

  public setUpPartner(no: string): Observable<boolean> {
    var partner = this.partners.value.find((p) => p.no == no);
    if (partner == null) return of(false);
    localStorage.setItem(this.partnerStorageName, JSON.stringify(partner));
    this.selectedPartner.next(partner);

    return this.getUserRoles(partner.no);
  }

  public cleanUpSelectedPartner() {
    this.selectedPartner.next(null);
  }

  private setUpUserStorage(user: BcUser) {
    localStorage.setItem(this.userStorageName, JSON.stringify(user));
    this.currentUserSubject.next({ ...user });
  }

  private initialSetUpUser() {
    var userStorage = localStorage.getItem(this.userStorageName);
    var user = null;
    if (userStorage && userStorage != null && userStorage != 'undefined') user = JSON.parse(userStorage);

    if (user != null) {
      this.isLoggedIn.next(true);
      this.currentUserSubject.next({ ...user });

      var partner = null;
      var partnerJson = localStorage.getItem(this.partnerStorageName);
      if (partnerJson && partnerJson != null && partnerJson != 'undefined') partner = JSON.parse(partnerJson);

      if (partner != null) {
        this.selectedPartner.next(partner);
      }
    }
  }

  public getApiPartners(): Observable<UserPartnersList> {
    return this.http.get<UserPartnersList>(`${environment.apiUrl}/Partners/GetAllForLoggedUser`).pipe(
      switchMap((data) => {
        this.isBcSuper = data.isBcSuper;
        this.partners.next(data.partners);
        this.partnersUpdated = true;

        var user = this.currentUserSubject.value;
        user.isBcViewer = data.isBcViewer;
        this.setUpUserStorage(user);

        if ((data.isBcSuper || data.isBcViewer) && data.partners.length == 1) {
          return this.setUpPartner(data.partners[0].no).pipe(map(() => data));
        }
        return of(data);
      })
    );
  }

  checkLsAdmStatus(): Observable<boolean> {
    return this.http.get<boolean>(`${environment.apiUrl}/Authentication/check-lsadm`).pipe(
      map((isLsAdm) => {
        var user = this.currentUserSubject.value;
        user.isLsAdmin = isLsAdm;
        user.authenticated = true;
        this.setUpUserStorage(user);
        return isLsAdm;
      })
    );
  }

  public updatePermissions(roles: string[], partnerNo: string): Observable<boolean> {
    var user = this.currentUserSubject.value;
    user.roles = roles;
    this.permissionsService.flushPermissions();
    localStorage.removeItem(this.permissionStorageName);

    if (roles) {
      this.permissionsService.loadPermissions(roles);

      var customerPermission =
        (user.roles?.includes(Role.BCSUPER) ||
          user.roles?.includes(Role.BCVIEWER) ||
          user.roles?.includes(Role.BCADMIN) ||
          user.roles?.includes(Role.BCCUSTOME)) &&
        !this.currentSelectedPartner.isDirEnd;

      this.permissionsService.addPermission(Role.CUSTOMERVISIBLE, (permissionName, permissionsObject) => {
        return customerPermission;
      });

      if (customerPermission) {
        roles.push(Role.CUSTOMERVISIBLE);
      }

      localStorage.setItem(this.permissionStorageName, JSON.stringify(roles));
    }
    return of(true);
  }

  public getUserRoles(partnerNo: string): Observable<boolean> {
    return this.http.post<string[]>(`${environment.apiUrl}/Partners/GetRoles/`, { no: partnerNo }).pipe(
      switchMap((roles) => {
        return this.updatePermissions(roles, partnerNo).pipe(switchMap(() => this.updateCompanies(partnerNo)));
      })
    );
  }

  public updateCompanies(partnerNo: string): Observable<boolean> {
    var user = this.currentUserSubject.value;
    if (
      !(user.roles?.includes(Role.BCADMIN) || user.roles?.includes(Role.BCSUPER) || user.roles?.includes(Role.BCVIEWER))
    ) {
      return of(false);
    }

    return this.getAdminCompanies(partnerNo).pipe(
      map((companies) => {
        user.bcAdminCompanies = companies;
        this.setUpUserStorage(user);
        return true;
      })
    );
  }

  public getAdminCompanies(partnerNo: string): Observable<CompanyBasic[]> {
    return this.http.post<CompanyBasic[]>(`${environment.apiUrl}/Partners/GetBcAdminCompanies/`, { no: partnerNo });
  }

  restorePermissions() {
    var data = localStorage.getItem(this.permissionStorageName);
    if (data != null) {
      var permissions = JSON.parse(data);
      this.permissionsService.loadPermissions(permissions);
    }
  }

  // public getPasswordChangeLinkRequest(email: string): Observable<string> {
  //   return this.http.get(`${environment.apiUrl}/Authentication/send-password-reset-link/` + email, {
  //     responseType: 'text',
  //   });
  // }

  // public getPasswordChangeRequest(params: ResetPasswordModel): Observable<string> {
  //   return this.http.post(`${environment.apiUrl}/Authentication/reset-password/`, params, { responseType: 'text' });
  // }

  public getUsersMap(): Observable<UserContactPartner[]> {
    return this.http.get<UserContactPartner[]>(`${environment.apiUrl}/Authentication/contact-partner-list`);
  }

  public completeAuthentication() {
    this.oidcSecurityService
      .checkAuth()
      .pipe(
        switchMap((loginResponse: LoginResponse) => {
          const { isAuthenticated, userData, accessToken, idToken, configId } = loginResponse;
          if (!isAuthenticated) return of(false);
          var user = userData;
          var expirationDate = new Date();
          expirationDate.setSeconds(expirationDate.getSeconds() + user.exp);
          const bcUser: BcUser = {
            id: user.sub,
            email: user.email,
            fullname: user.name,
            token: accessToken,
            tokenexpirationdate: expirationDate,
            isLsAdmin: false,
            isBcViewer: false,
            bcAdminCompanies: [],
            roles: [],
            authenticated: false,
          };
          this.setUpUserStorage(bcUser);
          this.isLoggedIn.next(true);
          return this.getApiPartners().pipe(switchMap(() => this.checkLsAdmStatus()));
        }),
        catchError((error: any) => {
          const errorFactory = () => new Error(error);

          // Optionally re-throw the error to propagate it further
          return throwError(errorFactory);
        })
      )
      .subscribe({
        next: () => {
          this.router.navigate(['/home']);
        },
        error: (error: any) => {
          this.logout();
        },
      });
  }
}
